export function formatDate(
    date: string,
    onlyMonth: boolean = false,
    locale: string = 'ru-RU',
) {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    }
    if (onlyMonth) {
        options.year = undefined
        options.day = 'numeric'
        options.month = 'numeric'
    }
    console.log(new Date(date).toLocaleDateString(locale, options))
    return new Date(date).toLocaleDateString(locale, options)
}

export enum PartnerTypeOrganization {
    RESTAURANT = 'restaurant',
    HOTEL = 'hotel',
    TOUR = 'tour',
    REGION = 'region',
}

export function ensureHttps(url:string) {
    const regex = /^(?!http[s]?:\/\/)/;
  
    if (regex.test(url)) {
      return 'https://' + url;
    }
    return url;
  }