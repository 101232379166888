import React, { useCallback } from "react";
import { Star } from "react-feather";

const StarRating = ({
  rating,
  color = "#583e7b",
}: {
  rating: number;
  color?: string;
}) => {
  // Function to generate star ratings
  const renderStars = useCallback(
    (rating: number) => {
      let stars = [];
      for (let i = 1; i <= Math.ceil(rating); i++) {
        stars.push(
          <Star
            key={i}
            color={i <= rating ? color : "#fff"}
            fill={i <= rating ? color : "#fff"}
            lightingColor={i <= rating ? color : "#fff"}
          />
        );
      }
      return stars;
    },
    [rating]
  );

  return <>{renderStars(rating)}</>;
};

export default StarRating;
