import React from 'react'
import { Col, Flex, Row } from 'antd'
import Image from 'next/image'
import { ArrowUpRight } from 'react-feather'
import Link from 'next/link'
import styles from './festival-box.module.scss'
import { formatDate } from '@/utils/mixed'

const FestivalBox: React.FC<EventListItem> = ({
    title,
    eventStartDate,
    eventEndDate,
    thumbnailUrl,
    address,
    slug,
    languageCode,
}) => {
    return (
        <Col lg={12} xs={12}>
            <div className={styles.festivalItem}>
                <Link href={`/${languageCode}/sobytiya/${slug}`}>
                    <Row gutter={12}>
                        <Col xs={24} lg={8}>
                            {thumbnailUrl && (
                                <Image
                                    src={thumbnailUrl}
                                    alt={title}
                                    width={178}
                                    height={174}
                                />
                            )}
                        </Col>
                        <Col xs={24} lg={16}>
                            <div className={styles.festivalContent}>
                                <h3>{title}</h3>
                                {address && <p>Фестиваль - {address}</p>}
                                <div className={styles.festivalFooter}>
                                    <span>{`${formatDate(
                                        eventStartDate.toString(),
                                        true,
                                    )} - ${formatDate(eventEndDate.toString(), true)}`}</span>
                                    <ArrowUpRight
                                        className={styles.arrow}
                                        size={50}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Link>
            </div>
        </Col>
    )
}

export default FestivalBox
