import { useQuery } from '@tanstack/react-query'
import { eventService } from '../services/event.service'
import { Locale } from '@/i18n'
import { CommonTourAndEventTypeList } from '@/types/mixed.types'

const useGetEventForDate = (
    locale: Locale,
    date?: Date,
    events?: EventListType | CommonTourAndEventTypeList | null,
) => {
    const fetchEvents = async (): Promise<EventListType | null> => {
        const response = await eventService.getEventByDate(locale, date)
        return response // Fallback to an empty array if null is returned
    }

    return useQuery<EventListType | CommonTourAndEventTypeList | null, Error>({
        initialData: events, // Fallback to an empty array if null is provided
        queryKey: ['events', date?.toISOString()],
        queryFn: fetchEvents,
        enabled: !!date,
    })
}

export default useGetEventForDate
