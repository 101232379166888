import { useQuery } from '@tanstack/react-query'
import { tourService } from '../services/tour.service'
import { Locale } from '@/i18n'
import { CommonTourAndEventTypeList } from '@/types/mixed.types'

// Hook to fetch tours with pagination
export const useTours = (
    locale: Locale,
    page: number = 1,
    limit: number = 10,
) => {
    const { isPending, error, data } = useQuery({
        queryKey: ['tours', page, limit],
        queryFn: () => tourService.getTours(locale, page, limit),
    })
    return { isPending, error, data }
}

export const useTourBySlug = (locale: Locale, slug?: string) => {
    const { isPending, error, data } = useQuery({
        queryKey: ['tour-by-slug', slug],
        queryFn: () => tourService.getTourBySlug(slug!, locale),
        enabled: !!slug,
    })
    return { isPending, error, data }
}

export const useGetToursForDate = (
    locale: Locale,
    date?: Date,
    tours?: TourListType | CommonTourAndEventTypeList | null,
) => {
    const fetchTourByDate = async (): Promise<TourListType | null> => {
        const dataParams = {
            startDate: date?.toISOString(),
        } as Record<string, string>
        const response = await tourService.getToursByFilter(locale, dataParams)
        return response // Fallback to an empty array if null is returned
    }

    return useQuery<TourListType | CommonTourAndEventTypeList | null, Error>({
        initialData: tours, // Fallback to an empty array if null is provided
        queryKey: ['toursForDate', date?.toISOString()],
        queryFn: fetchTourByDate,
        enabled: !!date,
    })
}
