'use client'
import React, { useEffect, useState } from 'react'
import Heading from '@/components/common/heading/Heading'
import { Col, Pagination, Row, Spin } from 'antd'
import BaseContainer from '@/components/common/base-container/BaseContainer'
import SingleDatePicker from '@/components/common/date-picker/SingleDatePicker'
import FestivalBox from './components/festival-box/festivalBox'
import Link from 'next/link'
import { ArrowUpRight } from 'react-feather'
import {
    HeadingType,
} from '../../../../../types/mixed.types'
import styles from './event.module.scss'
import useGetEventForDate from '@/components/page-modules/event/data/hooks/useGetEventForDate'
import { Locale } from '@/i18n'
import { useGetToursForDate } from '@/components/page-modules/tours/data/hooks/useTours'
import TourBox from '@/components/page-modules/tours/components/tour-box/TourBox'
import FestivalBoxTour from './components/festival-box/festivalBoxTour'

type CalendarOfEventType = {
    isShowTitle?: boolean
    cls?: string
    events: EventListType | null | any
    title: string
    allActivities: string
    locale: Locale
}

const CalendarOfEvents: React.FC<CalendarOfEventType> = ({
    isShowTitle,
    cls,
    events,
    title,
    allActivities,
    locale

}) => {

    const [selectedDate, setSelectedDate] = useState<Date>(new Date())
    const { data, isFetching } = useGetEventForDate(
        locale,
        selectedDate,
        events,
    )
    const { data: tourData, isFetching: tourFetching } = useGetToursForDate(
        locale,
        selectedDate,
    )

    const onChangeDate = (date: Date) => {
        setSelectedDate(date)
    }
    return (
        <div className={`${styles.eventWrapper} ${cls}`}>
            <BaseContainer>
                {isShowTitle && <Heading text={title} type={HeadingType.h2} />}
                <Row justify={'center'} gutter={{ lg: 24 }}>
                    <Col xs={24} lg={7}>
                        <SingleDatePicker
                            value={selectedDate}
                            onChangeDate={onChangeDate}
                            cls={styles.event_date}
                        />
                    </Col>
                    <Col lg={17} xs={24}>
                        {isFetching || tourFetching ? (
                            // Show a spinner or a loading message when data is being fetched
                            <div className={styles.loadingContainer}>
                                <Spin size="large" />{' '}
                                {/* Or any other loading indicator */}
                            </div>
                        ) : !data?.data.length && !tourData?.data.length ? (
                            <p>События не найдены.</p>
                        ) : (
                            <Row gutter={{ lg: 24, xs: 11 }}>
                                {data?.data?.map((event) => (
                                    <FestivalBox key={event.id} {...event} />
                                ))}
                                {// Show tours if there are any
                                    tourData?.data?.map((tour) => (
                                        <FestivalBoxTour key={tour.id} {...tour} />
                                    ))}
                            </Row>
                        )}
                    </Col>
                </Row>
            </BaseContainer>
        </div>
    )
}

export default CalendarOfEvents
