import { barista } from "@/styles/fonts";
import React from "react";
import styles from "./heading.module.scss";
enum HeadingType {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  h6 = "h6",  
}

interface HeadingProps {
  type?: HeadingType;
  text: string;
}

const Heading: React.FC<HeadingProps> = ({ type = HeadingType.h2, text }) => {
  const Tag = type as keyof JSX.IntrinsicElements;
  return <Tag className={`${barista.className} ${styles.heading}`}>{text}</Tag>;
};
export default Heading;
