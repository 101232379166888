import { API_BASE_URL } from '@/config/AppConfig'
import { cookies } from 'next/headers'
import { notFound } from 'next/navigation'

export const eventService = {
    async getEventByDate(
        locale: string,
        startDate?: Date,
        endDate?: Date,
    ): Promise<EventListType | null> {
        const generateQueryString = (date?: Date) => {
            if (!date) {
                return ''
            }
            return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        }

        // Construct the URL with conditional endDate.
        let url = `${API_BASE_URL}/event/byDate?startDate=${generateQueryString(startDate)}`
        if (endDate) {
            url += `&endDate=${generateQueryString(endDate)}`
        }
        const response = await fetch(`${url}`, {
            next: { revalidate: 60 },
            headers: {
                'x-accept-lang': locale,
            },
        })
        if (!response.ok) {
            return null
        }
        const data = await response.json()
        return data
    },
    async getEventBySlug(
        slug: string,
        locale: string,
    ): Promise<EventDetailType> {
        const response = await fetch(`${API_BASE_URL}/event/${slug}`, {
            next: { revalidate: 60 },
        })
        if (!response.ok) {
            notFound()
        }
        const data = await response.json()

        return data
    },
}
