import React from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/ru' // Import the Russian locale
import 'dayjs/locale/en'
import 'dayjs/locale/zh-cn' // Import the Russian locale
// Import the Russian locale

import type { Dayjs } from 'dayjs'
import dayLocaleData from 'dayjs/plugin/localeData'
import {Calendar, Col, Radio, Row, Select, Typography, theme, Input} from 'antd'
import type { CalendarProps } from 'antd'
import { useLocale, useTranslations } from 'next-intl'

type SingleDatePickerType = {
    value?: any
    cls?: string
    onChangeDate?: (date: Date) => void
}

const SingleDatePicker: React.FC<SingleDatePickerType> = ({
    value,
    cls,
    onChangeDate,
}) => {
    const { token } = theme.useToken()
    const locale = useLocale()
    const t= useTranslations("calendar")
    dayjs.extend(dayLocaleData)
    dayjs.locale(locale === 'cn' ? 'zh-cn' : locale)

    const onPanelChange = (
        value: Dayjs,
        mode: CalendarProps<Dayjs>['mode'],
    ) => {
        onChangeDate && onChangeDate(value.toDate())
    }

    const wrapperStyle: React.CSSProperties = {
        border: `1px solid ${token.colorBorderSecondary}`,
        borderRadius: token.borderRadiusLG,
    }

    return (
        <div className={cls} style={wrapperStyle}>
            <Calendar
                fullscreen={false}
                headerRender={({ value, type, onChange, onTypeChange }) => {
                    const start = 0
                    const end = 12
                    const monthOptions = []
                    let current = value.clone()
                    const localeData = value.localeData()
                    const months = []

                    for (let i = 0; i < 12; i++) {
                        current = current.month(i)
                        months.push(localeData.monthsShort(current))
                    }
                    for (let i = start; i < end; i++) {
                        monthOptions.push(
                            <Select.Option
                                key={i}
                                value={i}
                                className="month-item"
                            >
                                {months[i]}
                            </Select.Option>,
                        )
                    }

                    const year = value.year()
                    const month = value.month()

                    const options = []
                    for (let i = year - 10; i < year + 10; i += 1) {
                        options.push(
                            <Select.Option
                                key={i}
                                value={i}
                                className="year-item"
                            >
                                {i}
                            </Select.Option>,
                        )
                    }
                    return (
                        <div style={{ padding: 8 }}>
                            <Row gutter={8}>
                                <Col>
                                    <Radio.Group
                                        size="small"
                                        onChange={(e) =>
                                            onTypeChange(e.target.value)
                                        }
                                        value={type}
                                    >
                                        <Radio.Button value="day">
                                            {t("day")}
                                        </Radio.Button>
                                        {/* Corrected from "year" to "month" for clarity */}
                                    </Radio.Group>
                                </Col>
                                <Col>
                                    <Select
                                        size="small"
                                        dropdownMatchSelectWidth={false}
                                        className="my-year-select"
                                        value={year}
                                        onChange={(newYear) => {
                                            const now = value
                                                .clone()
                                                .year(newYear)
                                            onChange(now)
                                        }}
                                    >
                                        {options}
                                    </Select>
                                </Col>
                                <Col>
                                    <Select
                                        size="small"
                                        dropdownMatchSelectWidth={false}
                                        value={month}
                                        onChange={(newMonth) => {
                                            const now = value
                                                .clone()
                                                .month(newMonth)
                                            onChange(now)
                                        }}
                                    >
                                        {monthOptions}
                                    </Select>
                                </Col>
                            </Row>
                        </div>
                    )
                }}
                onPanelChange={onPanelChange}
                onChange={(val) => onChangeDate?.(val.toDate())}
            />
        </div>
    )
}

export default SingleDatePicker
