export enum HeadingType {
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
    h5 = 'h5',
    h6 = 'h6',
}

export type GalleryImageType = {
    images: string[]
    selectedImage: string
}

export type ComponentDetailPageType = {
    params: Record<string, any>
    searchParams?: Record<string, string>
}

export interface SeasonType {
    id: number
    title: string
}
export interface LocationType {
    id: number
    title: string
}

export interface TourTypeListType {
    id: number
    title: string
}

export type ComponentListPageType = {
    searchParams: Record<string, string>
    params: Record<string, any>
}

export type PagerType = {
    totalItems: number
    currentPage: number
    pageSize: number
    totalPages: number
    startPage: number
    endPage: number
    startIndex: number
    endIndex: number
    pages: [number]
}

export enum ReviewEntityType {
    tour = 1,
    restaurant = 2,
    hotel = 3,
    attraction = 4,
    event = 5,
    entertainment = 6,
    aboutYakutia = 7,

}

export type CommonTourAndEventTypeList = {
    data: CommonTourAndEventItemType[]
    pager: PagerType
}

export type CommonTourAndEventItemType = {
    pager: PagerType
    id: number
    title: string
    heading: string
    kmFromCenterText?: string
    thumbnailUrl: string
    rating?: number
    slug: string
    price?: number
    combinedDayText?: string
    companyName?: string
    companyLogo?: string
    address?: string
    eventStartDate: Date
    eventEndDate: Date
    languageCode: string
}
